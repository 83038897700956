'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import { Button, Dialog, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import {
  ConfirmDialog,
  Pagination,
  toast,
  ToastAction,
  ToastTypeEnums,
  ToggleFilter,
  ColumnAdvance,
  TableColumnSettings,
  ConfirmDialogRef,
  clearFilter,
  parseFilterToArrayOfTagsData,
  CommonTable,
  SimpleSearch,
  TableFilter,
} from '@/components/common';
import { useTranslations } from 'next-intl';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { AllDocumentsFilterForm, FilterAllDocumentsParams } from '../header-filter/all-documents-filter-form';
import { PreviewDocumentDialog, PreviewDocumentDialogRef } from '../preview/preview-documents.dialog';
import { getAllDocumentsColumns } from './all-documents-columns';
import { useDocumentsQuery } from '@/hooks/fetchers/queries/documents/useDocumentsQuery';
import { useRemoveDocumentMutation } from '@/hooks/fetchers/mutations/documents/useRemoveDocumentMutation';
import { useRemoveUndoDocumentMutation } from '@/hooks/fetchers/mutations/documents/useRemoveUndoDocumentMutation';
import { Client, Document, Service, Therapist } from '../interfaces/document.interface';
import { CreateManualDocumentForm } from '../manage/create-manual-document-form';
import {
  CreateDocumentViaTemplateDialog,
  CreateDocumentViaTemplateDialogRef,
} from '../manage/create-document-via-template.dialog';
import { cn } from '@/lib/utils';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { DOCUMENT_TYPE_TYPES, TABLE_TYPES } from '@/common/enums';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { useTableFilterDefaultQuery } from '@/hooks/fetchers/queries/useTableFilterDefaultQuery';
import { useTableFilterDefaultMutation } from '@/hooks/fetchers/mutations/useTableFilterDefaultMutation';
import { allDocumentsSortingHelper } from './all-documents-sorting-helper';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';

const PER_PAGE = 100;

interface Params {
  clientId?: string;
  serviceId?: string;
  therapistId?: string;
  tableClassName?: string;
  hideCreate?: boolean;
  hideTableSettings?: boolean;
}

export function AllDocumentsTable({
  clientId,
  serviceId,
  therapistId,
  tableClassName,
  hideCreate,
  hideTableSettings,
}: Params) {
  const t = useTranslations();
  const [data, setData] = useState<Document[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createDocumentDialogOpen, setCreateDocumentDialogOpen] = useState(false);
  const [actionPopoverOpen, setActionPopoverOpen] = useState(false);
  const [filter, setFilter] = useState<FilterAllDocumentsParams>({});
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewDocumentDialogRef = createRef<PreviewDocumentDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();

  const createDocumentViaTemplateDialogRef = createRef<CreateDocumentViaTemplateDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions, currentRole } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.ALL_DOCUMENTS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableDefFilters } = useTableFilterDefaultQuery(TABLE_TYPES.ALL_DOCUMENTS);
  const { mutate: setTableDefFilters } = useTableFilterDefaultMutation();
  const {
    data: tableData,
    refetch: refetchDocuments,
    isLoading,
  } = useDocumentsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    clientId,
    showDeleted,
    serviceId,
    therapistId,
    search,
    ...filter,
    ...allDocumentsSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
    if (filter) {
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [JSON.stringify(filter)]);

  const { mutate: removeDocument } = useRemoveDocumentMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoDocument(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchDocuments();
    },
  });

  const { mutate: removeUndoDocument } = useRemoveUndoDocumentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchDocuments();
    },
  });

  const handleConfirmRemove = (row: Row<Document>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteDocument'),
      value: row.original.id,
    });
  };

  const handlePreviewClient = (client: Client) => {
    previewClientDialogRef.current?.openById(client.id);
  };

  const handlePreviewService = (service: Service) => {
    previewServiceDialogRef.current?.openById(service.id);
  };

  const handlePreviewTherapist = (therapist: Therapist) => {
    previewTherapistDialogRef.current?.openById(therapist.id);
  };

  const table = useReactTable({
    columns: getAllDocumentsColumns({
      t,
      onPreviewClient: handlePreviewClient,
      onPreviewService: handlePreviewService,
      onPreviewTherapist: handlePreviewTherapist,
      onConfirmRemove: handleConfirmRemove,
      checkPermissions,
      currentRole,
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Document>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Document>[]) => {
    setTableConfig({
      name: TABLE_TYPES.ALL_DOCUMENTS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  useEffect(() => {
    if (tableDefFilters && tableDefFilters.filters) {
      setFilter(tableDefFilters.filters);
    }
  }, [JSON.stringify(tableDefFilters)]);

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Document>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          onSaveAsDefaultFilter={(filters) => {
            setTableDefFilters({
              name: TABLE_TYPES.ALL_DOCUMENTS,
              filters,
            });
          }}
          formComponent={AllDocumentsFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          {!hideCreate && checkPermissions([PERMISSIONS_TYPES.MANUAL_CREATION_OF_DOCUMENT]) ? (
            <Popover open={actionPopoverOpen} onOpenChange={setActionPopoverOpen}>
              <PopoverTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 size-4" /> {t('Buttons.addDocument')}
                </Button>
              </PopoverTrigger>
              <PopoverContent withoutPortal align="start" className="w-40">
                <div className="flex flex-col space-y-4">
                  <Dialog open={createDocumentDialogOpen} onOpenChange={setCreateDocumentDialogOpen}>
                    <DialogTrigger asChild>
                      <Button size="lg" variant="outline" className="w-full">
                        {t('Buttons.manually')}
                      </Button>
                    </DialogTrigger>
                    <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
                      <DialogHeader>
                        <DialogTitle>{t('Pages.AllDocuments.createDocument')}</DialogTitle>
                        <DialogDescription></DialogDescription>
                      </DialogHeader>
                      <CreateManualDocumentForm
                        onCancel={() => setCreateDocumentDialogOpen(false)}
                        onCreate={() => {
                          setCreateDocumentDialogOpen(false);
                          refetchDocuments();
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                  <Button
                    onClick={() => createDocumentViaTemplateDialogRef.current?.open()}
                    size="lg"
                    variant="outline"
                    className="w-full"
                  >
                    {t('Buttons.template')}
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
          ) : null}
          {!hideTableSettings && <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />}
        </div>
      </header>

      <div className="mb-2 grid h-10 w-full max-w-60 grid-cols-2 gap-1">
        {checkPermissions([PERMISSIONS_TYPES.VIEW_FILTER_ARCHIVED]) && (
          <>
            <ToggleFilter label={t('Buttons.active')} onChange={() => setShowDeleted(false)} isActive={!showDeleted} />
            <ToggleFilter label={t('Buttons.archived')} onChange={() => setShowDeleted(true)} isActive={showDeleted} />
          </>
        )}
      </div>

      <div className={cn('relative h-[calc(100vh-296px)] max-w-full overflow-auto', tableClassName)}>
        <div className="absolute w-full">
          <CommonTable
            disableRowFn={(row: Row<Document>) => {
              if (
                (row.original.type === DOCUMENT_TYPE_TYPES.Progress ||
                  row.original.type === DOCUMENT_TYPE_TYPES.Discharge) &&
                row.original.expiryDate &&
                currentRole !== ROLE_TYPES.Admin &&
                currentRole !== ROLE_TYPES['Super admin'] &&
                currentRole !== ROLE_TYPES.Coordinator
              ) {
                const today = new Date();
                const sixMonthsLater = new Date();
                sixMonthsLater.setMonth(today.getMonth() + 6); // Add 6 months to today
                return new Date(row.original.expiryDate) > sixMonthsLater;
              }
              return false;
            }}
            table={table}
            onClickByRow={(rowOriginal) => previewDocumentDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeDocument(id)}
        title={t('Common.deleteDocument')}
        type="delete"
      />
      <PreviewClientDialog onUpdated={refetchDocuments} ref={previewClientDialogRef} />
      <PreviewServiceDialog onUpdated={refetchDocuments} ref={previewServiceDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchDocuments} ref={previewTherapistDialogRef} />

      <PreviewDocumentDialog ref={previewDocumentDialogRef} />
      <CreateDocumentViaTemplateDialog ref={createDocumentViaTemplateDialogRef} />
    </>
  );
}
